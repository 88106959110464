import {
  getUserNamespacedCookie,
  removeUserNamespacedCookie,
  setUserNamespacedCookie,
} from '@@/src/shared/lib/helpers/store/cookie';
import {
  toTimezoneOffsetISOString,
} from '@@/src/shared/lib/helpers/date/toTimezoneOffsetISOString';
import { PipelineType } from '@@/src/shared/api/Meeting/types';
import { useProjectsStore } from '@@/src/shared/@pinia/use-projects-store';
import { useProjectsService } from '@@/src/shared/lib/services/projects/Projects.service';
import { useClientStore,
  useFavoritesStore,
  useFlatsStore,
  usePortraitsStore,
  useSurveyStore } from '../shared/@pinia';

// FixMe: обсудить с бэком — это нужно убрать
// region Default meeting value
const DEFAULT_MEETING_VALUE = {
  purchase_purpose: null,
  money: '',
  form_pay: 0,
  rooms: [],
  area: {
    lower: 0,
    upper: 0,
  },
  floor: {
    lower: 0,
    upper: 0,
  },
  another_type: ['0', '0'],
  adult_count: 0,
  child_count: 0,
  child_len: [],
  ad_question: '',
  ad_question_custom: '',
  stage_solution_question: '',
  stage_solution_question_custom: '',
  comment: '',
  state: '',
};
// endregion

const START_MEETING_REDIRECT_ROUTE = 'project-slug-company';

export const state = () => ({
  meeting: {},
  presentationId: '',
  tracking: {},
  flatsValues: {},
  isDemoStarted: false,
  galleryCategoryName: '',
  searchId: '',
  surveyResult: {}, // Собирает значения по шагам перед одним общим updateMeeting
});

// noinspection JSUnusedGlobalSymbols
export const getters = {
  getMeetingId(state) {
    return state.meeting?.id;
  },

  getMeetingBookedLotId(state) {
    return state.meeting?.booked_property;
  },

  getPresentationId(state) {
    return state.presentationId;
  },

  getMeetingHomeLink(_state, getters, _rootState) {
    let name;
    const params = {
      slug: useProjectsStore().activeProjectSlug,
    };
    const isMeetingInProgress = getters.isMeetingInProgress;
    // const isDemoStarted = state.isDemoStarted;

    if (isMeetingInProgress) {
      name = 'project-slug-meeting-finish';
      // name = 'project-slug-meeting-step';
      // params.step = isDemoStarted ? 'finish' : '1';
    }
    else {
      name = 'project-slug-meeting-index';
    }

    return {
      name,
      params,
    };
  },

  /**
   *
   * @param state
   * @returns {PipelineType}  Offline |  Online
   */
  getPipeline(state) {
    return state?.meeting?.pipeline?.type === PipelineType.Online
      ? PipelineType.Online
      : PipelineType.Offline;
  },

  isMeetingInProgress(state) {
    return Boolean(state.meeting?.id);
  },

  isDemoAllowed(state) {
    return (
      Boolean(state.meeting?.id)
    );
  },

  getMeetingClientId(state) {
    return state.meeting?.client?.id;
  },

  getMeetingClientCrmId(state) {
    return state.meeting?.client?.id_crm;
  },

  getMeetingClientName(state) {
    return state.meeting?.client?.name;
  },

  getMeetingCrmId(state) {
    return state.meeting?.id_crm;
  },

  getCurrentMeeting(state) {
    return {
      ...state.meeting,
      ...useSurveyStore().meeting ?? {},
    };
  },

  getFlatValues(state) {
    return state.flatsValues;
  },

  getSearchId(state) {
    return state.searchId;
  },

  getBookingTypes(state) {
    return state.meeting?.booking_types;
  },

  getPaymentTypes(state) {
    return state.meeting?.payment_types;
  },
};

export const actions = {
  setMeeting({ commit }, payload) {
    commit('SET_MEETING', payload);

    setUserNamespacedCookie(
      this.$cookies,
      'meeting',
      payload?.id || '',
      this.$auth.user?.id,
    );
  },

  setMeetingBookedLotId({ commit, getters, state }, payload) {
    const { isMeetingInProgress } = getters;
    if (!isMeetingInProgress) {
      return;
    }
    const { meeting } = state;

    commit('SET_MEETING', {
      ...meeting,
      booked_property: payload,
    });
  },

  resetMeeting({ commit }) {
    commit('RESET_MEETING');

    const userId = this.$auth.user?.id;

    try {
      removeUserNamespacedCookie(this.$cookies, 'isDemoStarted', userId);
      removeUserNamespacedCookie(this.$cookies, 'meeting', userId);
      removeUserNamespacedCookie(this.$cookies, 'presentationId', userId);
    }
    catch (e) {
      console.warn(e.message);
    }
  },

  setDemo({ commit }, payload) {
    commit('SET_DEMO', payload);

    setUserNamespacedCookie.apply({}, [
      this.$cookies,
      'isDemoStarted',
      payload,
      this.$auth.user?.id,
    ]);
  },

  setPresentationId({ commit }, payload) {
    commit('SET_PRESENTATION_ID', payload);

    setUserNamespacedCookie.apply({}, [
      this.$cookies,
      'presentationId',
      payload,
      this.$auth.user?.id,
    ]);
  },

  async startMeeting(
    {
      dispatch,
      rootState,
    },

    meeting = rootState.meetings.active.meeting,
  ) {
    const {
      id,
      client,
    } = meeting;

    const projectsStore = useProjectsStore();
    const clientStore = useClientStore();

    const currentProjectId = String(projectsStore.activeProjectId);
    const meetingProjectId = String(meeting.project);

    let slug = projectsStore.activeProjectSlug;

    if (meetingProjectId !== currentProjectId) {
      const projectItem = projectsStore.getProjectByProfitbaseId(meetingProjectId);
      slug = projectItem.slug;
    }

    // Отправка запроса на установку активной встречи
    await dispatch('sendStartMeeting', id);

    await useProjectsService().setUserActiveProject(slug);

    // Установка данных клиента
    if (meeting.client) {
      clientStore.setClient(client);
    }

    // Сброс данных (квартиры, избранное, ипотека)
    await dispatch('resetSessionState', undefined, {
      root: true,
    });

    await this.$router.push(
      {
        name: START_MEETING_REDIRECT_ROUTE,
        params: {
          slug,
        },
      },
    );
  },

  async getMeeting({
    dispatch,
  }, id) {
    try {
      const response = await this.$panelApi.$get(this.$routes.meetings.meeting(id));
      dispatch('setMeeting', response);
    }
    catch (e) {
      dispatch('resetMeeting');
      console.warn('[meeting/getMeeting] GET request failed: ', e);
    }
  },

  async getActiveMeeting(
    {
      dispatch,
    },
    {
      isSkipExistingMeeting = false,
    },
  ) {
    try {
      const userId = this.$auth.user?.id;

      const projectsStore = useProjectsStore();
      const clientStore = useClientStore();

      let isKeepProject = false;
      const localMeetingId = getUserNamespacedCookie(this.$cookies, 'meeting', userId);
      const response = await this.$panelApi.$get(this.$routes.meetings.current);

      const responseId = response?.id;

      if (!responseId) {
        dispatch('resetMeeting');
        return;
      }

      if (isSkipExistingMeeting && localMeetingId === responseId) {
        isKeepProject = true;
      }

      const { client } = response || {};

      dispatch('setMeeting', response);

      if (client) {
        clientStore.setClient(client);
      }

      const presentationId = response.last_presentation_id;

      if (presentationId) {
        dispatch('setPresentationId', presentationId);
      }

      useSurveyStore().clearSurveyMeeting();

      if (!isKeepProject) {
        // Установка жк из полученной встречи
        const isDifferentProjects = projectsStore.activeProjectId !== response.project;
        if (isDifferentProjects) {
          const slug = projectsStore.projectsList?.find(({ id }) => id === response.project)?.slug;

          if (!slug) {
            return;
          }

          await useProjectsService().setUserActiveProject(slug);
        }
      }
    }
    catch (e) {
      // Если нет активной встречи, сбрасываем митинг.
      // Для корректного отображения на различных устройствах
      dispatch('resetMeeting');
      console.warn('[meeting/getActiveMeeting] No current meeting');
    }
  },

  async createMeeting({
    dispatch,
  }, payload) {
    try {
      const response = await this.$panelApi.$post(this.$routes.meetings.base, payload);
      dispatch('resetMeeting');
      if (response?.id) {
        await dispatch('getMeeting', response.id);
      }
    }
    catch (e) {
      dispatch('resetMeeting');
      console.warn('[meeting/createMeeting] POST request failed: ', e);
    }
  },

  async endMeeting({
    getters,
    state,
  }, isMeetCanceled) {
    const {
      client,
      details,
      form_pay,
      money,
    } = state.meeting;

    await this.$panelApi.$post(this.$routes.meetings.finish(getters.getCurrentMeeting.id), {
      project: useProjectsStore().activeProjectId,
      ...DEFAULT_MEETING_VALUE,
      client,
      details,
      form_pay,
      money,
      ...useSurveyStore().meeting,
      datetime_end: toTimezoneOffsetISOString(new Date()),
      is_cancel: isMeetCanceled,
    });

    useFlatsStore().resetSavedValues();
  },

  // FIXME объединенить со startMeeting
  // FixMe создать action setMeeting который будет сохранять данные о встрече либо после запуска
  //       встречи или после получение активной встречи
  async sendStartMeeting({
    getters,
  }, id = getters.getCurrentMeeting.id) {
    await this.$panelApi.$post(this.$routes.meetings.start(id), {
      datetime_start_fact: toTimezoneOffsetISOString(new Date()),
    });
  },

  async removeMeeting({
    dispatch,
  }, id) {
    try {
      await this.$panelApi.$delete(this.$routes.meetings.meeting(id));
    }
    catch (e) {
      console.warn('[meeting/removeMeeting] DELETE request failed: ', e);
    }
    finally {
      dispatch('finishMeeting');
    }
  },

  async finishMeeting({
    dispatch,
    commit,
  }) {
    await dispatch('resetMeeting');

    await useFavoritesStore().synchronizeFavorites();
    await dispatch('resetSessionState', {}, { root: true });

    commit('RESET_TRACKING');
    commit('RESET_FLAT_VALUES');

    useSurveyStore().clearSurveyMeeting();
  },

  startDemo({ dispatch }) {
    dispatch('setDemo', true);
  },

  setFlatValues(
    { commit },
    payload,
  ) {
    commit(
      'SET_FLAT_VALUES',
      {
        another_type: payload.another_type?.length ? payload.another_type : [],
      },
    );
  },

  resetFlatValues({ commit }) {
    commit('RESET_FLAT_VALUES');
  },

  async initPresentation({
    getters,
    dispatch,
  }) {
    if (!getters.getMeetingId) {
      return null;
    }

    try {
      const presentation = await this.$panelApi.$post(this.$routes.statistic.addPresentation, {
        meeting: getters.getMeetingId,
        portrait: usePortraitsStore().currentPortrait,
      });

      if (presentation?.id) {
        dispatch('setPresentationId', presentation.id);
      }
    }
    catch (e) {
      console.warn('[meeting/initPresentation] POST request failed: ', e);
    }
  },

  startTracking({
    getters,
    commit,
  }, payload) {
    if (!getters.getMeetingId) {
      return;
    }

    commit('SET_TRACKING', {
      name: payload.name,
      route: payload.route,
      timestamp: Number(new Date()),
    });
  },

  async endTracking({
    state,
    getters,
    commit,
    dispatch,
  }) {
    const slide = state.tracking?.name;
    const timestamp = state.tracking?.timestamp;

    if (!getters.getMeetingId || !slide || !timestamp) {
      return;
    }

    if (!getters.getPresentationId) {
      await dispatch('initPresentation');
    }
    if (!getters.getPresentationId) {
      return;
    }

    const time = new Date(Number(new Date()) - timestamp)
      .toISOString()
      .slice(11, -5);

    try {
      await this.$panelApi.$post(this.$routes.statistic.base, {
        presentation_statistic: getters.getPresentationId,
        slide,
        time,
      });
    }
    catch (e) {
      //
    }

    commit('RESET_TRACKING');
  },

  setGallerySubcategoryName({
    commit,

  }, payload) {
    commit('SET_GALLERY_CATEGORY_NAME', payload);
  },

  changeSearchId({
    commit,

  }, payload) {
    commit('SET_SEARCH_ID', payload);
  },

  updateSurveyResult({
    commit,
  }, payload) {
    commit('SET_SURVEY_RESULT', {
      ...useSurveyStore().meeting,
      ...payload,
    });
  },
};

export const mutations = {
  SET_MEETING(state, payload) {
    state.meeting = payload;
  },

  RESET_MEETING(state) {
    state.isDemoStarted = false;
    state.meeting = {};
    state.presentationId = '';
  },

  SET_PRESENTATION_ID(state, payload) {
    state.presentationId = payload;
  },

  SET_DEMO(state, payload) {
    state.isDemoStarted = payload;
  },

  SET_FLAT_VALUES(state, payload) {
    state.flatsValues = {
      ...state.flatsValues,
      ...payload,
    };
  },

  RESET_FLAT_VALUES(state) {
    state.flatsValues = {};
  },

  RESET_TRACKING(state) {
    state.tracking = {
      name: '',
      route: '',
    };
  },

  SET_TRACKING(state, payload) {
    state.tracking = payload;
  },

  SET_GALLERY_CATEGORY_NAME(state, payload) {
    state.galleryCategoryName = payload;
  },

  SET_SEARCH_ID(state, payload) {
    state.searchId = payload;
  },

  SET_SURVEY_RESULT(state, payload) {
    state.surveyResult = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
